import cn from 'classnames'
import { Search } from '../Search'
import { SynagogueList } from '../SynagogueList'
import { useMapStore } from '../../../store'
import { SynagogueDescription } from '../SynagogueDescription'
import { SelectedAdress } from '../SelectedAdress'
import styles from './Sidebar.module.css'
import { useEffect } from 'react'

export const Sidebar = (props: {
  title: string | null | undefined
}) => {
  // const navigate = useNavigate()
  const redirectCountDown = useMapStore((state) => state.redirectCountDown)
  const setRedirectCountDown = useMapStore((state) => state.setRedirectCountDown)

  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const resetSearchedCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)
  const setCurrentPage = useMapStore((state) => state.setCurrentPage)
  const updateGalleryModal = useMapStore((state) => state.updateGalleryModal)
  const updateOverlay = useMapStore((state) => state.setOverlayCountDown)
  const isGeocoderError = useMapStore((state) => state.isGeocoderError)
  const setGeocoderError = useMapStore((state) => state.setGeocoderError)
  const nearestChabad = useMapStore((state) => state.nearestChabadId)
  const setNearestChabadId = useMapStore((state) => state.setNearestChabadId)
  const setFocusedInput = useMapStore((state) => state.setFocusedInput)
  const setIsFeedBackModalOpened = useMapStore((state) => state.setIsFeedBackModalOpened)

  const selectedId = useMapStore((state) => state.selectedId)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const adress = useMapStore((state) => state.adress)
  const geoObjectsList = useMapStore((state) => state.geoObjectsList)
  const synagogoguesList = useMapStore((state) => state.synagoguesList)
  const setIsPolicyVisible = useMapStore((state) => state.setIsPolicyVisible)

  useEffect(() => {
    let timer

    timer = setInterval(() => {
      setRedirectCountDown(redirectCountDown - 1)
    }, 1000)

    if (redirectCountDown === 0) {
      clearInterval(timer)
      resetSelectedId()
      resetSearchedCoordinates()
      resetAdress()
      resetGeoObjectsList()
      updateGalleryModal({ isOpened: false, id: null })
      setSynagoguesList([])
      updateOverlay({ isOpened: true, timeLeft: 60 })
      setRedirectCountDown(60)
      setGeocoderError(false)
      setNearestChabadId(null)
      setFocusedInput('')
      setIsFeedBackModalOpened(false)
      setIsPolicyVisible(false)

      hideKeyboard()
      setCurrentPage('home')
    }

    return () => clearInterval(timer)
  }, [resetAdress, resetGeoObjectsList, resetSearchedCoordinates, resetSelectedId, setCurrentPage, setSynagoguesList, updateGalleryModal, redirectCountDown, setRedirectCountDown, updateOverlay, setGeocoderError, setNearestChabadId, hideKeyboard, setFocusedInput, setIsFeedBackModalOpened, setIsPolicyVisible])

  return (
    <aside className={cn({
      [styles.wrapper]: !selectedId,
      [styles.wrapper_active]: selectedId,
    })}
      onClick={() => setRedirectCountDown(90)}
      onTouchMove={() => setRedirectCountDown(90)}
    >
      <img
        className={styles.logo}
        src="/icons/sidebar-header.svg"
        alt="Лого музея"
        onClick={() => {
          resetSelectedId()
          resetSearchedCoordinates()
          resetAdress()
          resetGeoObjectsList()
          updateGalleryModal({ isOpened: false, id: null })
          setSynagoguesList([])
          updateOverlay({ isOpened: false, timeLeft: 60 })
          setRedirectCountDown(60)
          setGeocoderError(false)
          setNearestChabadId(null)
          hideKeyboard()
          setCurrentPage('home')
        }}
      />
      {!selectedId && geoObjectsList.length === 0 && <Search />}
      
      {!selectedId && geoObjectsList.length > 0 && adress && adress.length > 0 &&
        <SelectedAdress adress={adress}
      />}

      {
        !selectedId && geoObjectsList.length === 0 && !isGeocoderError && !nearestChabad && <SynagogueList
          title={props.title}
          isSynagoguesList
          synagogoguesList={synagogoguesList}
          onClick={() => {
            // resetAdress()
            hideKeyboard()
          }}
        />
      }

      {
        selectedId && !nearestChabad && <SynagogueDescription />
      }

      {
        selectedId && nearestChabad && <SynagogueDescription isBackToAdress />
      }

      {
        geoObjectsList.length > 0 && geoObjectsList[0].adress && !selectedId && !nearestChabad &&
        <SynagogueList
          title={props.title}
          isGeoObjectList
          geoObjectsList={geoObjectsList}
          onClick={() => {
            // resetAdress()
            hideKeyboard()
          }}
        />
      }

      {     
        geoObjectsList.length === 1 && !geoObjectsList[0].adress && !isGeocoderError &&
        <SynagogueList isError title={props.title} />
      }

      {     
        isGeocoderError &&
        <SynagogueList isGeocoderError title={props.title} />
      }

      {
        nearestChabad && !selectedId && <SynagogueList
          title={props.title}
          isSynagoguesList
          isFilteredByNearestId
          nearestChabadId={nearestChabad}
          synagogoguesList={synagogoguesList}
          onClick={() => {
            hideKeyboard()
          }}
        />
      }
    </aside>
  )
}