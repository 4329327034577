import { useEffect, useRef, useState } from 'react'
import { useMapStore } from '../../../store'
import { motion } from 'framer-motion'
import styles from './Search.module.css'

export const Search = (() => {
  const adress = useMapStore((state) => state.adress)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setIsKeyboardVisible = useMapStore((state) => state.setIsKeyboardVisible)
  const setFocusedInput = useMapStore((state) => state.setFocusedInput)
  const isFeedBackModalOpened = useMapStore((state) => state.isFeedBackModalOpened)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isKeyboardVisible && inputRef && !isFeedBackModalOpened) {
      inputRef.current!.focus()
    } else {
      inputRef.current!.blur()
    }
  }, [isFeedBackModalOpened, isKeyboardVisible])

  // const handleBlur = useCallback(() => {
  //   if (inputRef.current && isKeyboardVisible) {
  //     inputRef.current.focus()
  //   } 
    
  //   if (inputRef.current && !isKeyboardVisible) {
  //     inputRef.current.blur()
  //   }
  // }, [isKeyboardVisible])

  // useEffect(() => {
  //   handleBlur()
  // }, [handleBlur])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth
    }
  }, [adress])
  
  return <motion.div
    className={styles.wrapper}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <div className={styles.input__wrapper}>
      <label className={styles.title}>Поиск</label>
      <input
        ref={inputRef}
        className={styles.input}
        type="text"
        placeholder="введите город и улицу"
        value={adress || ''}
        onFocus={() => {
          setFocusedInput('adress')
          setIsKeyboardVisible()
        }}
        readOnly
      />
      <img className={styles.map__pin} src="/icons/map-pin.svg" alt="Метка" />
      {/* <img className={styles.search__icon} src="/search-icon.svg" alt="лупа" /> */}
    </div>
  </motion.div>
})