import { useMapStore } from '../../../store'
import { PersonalInfoModal } from '../../../types/directus'
import { TouchKeyboard } from '../../ui/TouchKeyboard'
import { AnimatePresence, motion } from 'framer-motion'
import styles from './FeedbackModal.module.css'
import { useEffect, useRef, useState } from 'react'
import { HTMLBlock } from '../../ui/HTMLBlock'
import { createFeedBack } from '../../../utils/directus/requests'

export const FeedbackModal = (props: {
  infoModalElements: PersonalInfoModal
  closeModal: () => void
  onClick: () => void
  isModalVisible: boolean
}) => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const messengerInputRef = useRef<HTMLInputElement>(null)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setIsKeyboardVisible = useMapStore((state) => state.setIsKeyboardVisible)
  const focusedInput = useMapStore((state) => state.focusedInput)
  const setFocusedinput = useMapStore((state) => state.setFocusedInput)
  const nameInputValue = useMapStore((state) => state.nameInputValue)
  const phoneInputValue = useMapStore((state) => state.phoneInputValue)
  const messengerInputValue = useMapStore((state) => state.messengerInputValue)
  const emailInputValue = useMapStore((state) => state.emailInputValue)
  const commentTextArea = useMapStore((state) => state.commentTextArea)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const isFeedBackModalOpened = useMapStore((state) => state.isFeedBackModalOpened)
  const isFeedbackSent = useMapStore((state) => state.isFeedbackSent)
  const setIsFeedbackSent = useMapStore((state) => state.setIsFeedbackSent)
  const isPolicyVisible = useMapStore((state) => state.isPolicyVisible)
  const setIsPolicyVisible = useMapStore((state) => state.setIsPolicyVisible)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [phoneValue, setPhoneValue] = useState('')

  useEffect(() => {
    if (nameInputValue.length > 0 && phoneInputValue.length > 9) {
      setIsSubmitButtonDisabled(false)
    } else {
      setIsSubmitButtonDisabled(true)
    }
  }, [nameInputValue, phoneInputValue])

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.scrollLeft = nameInputRef.current.scrollWidth
    }

    if (emailInputRef.current) {
      emailInputRef.current.scrollLeft = emailInputRef.current.scrollWidth
    }

    if (messengerInputRef.current) {
      messengerInputRef.current.scrollLeft = messengerInputRef.current.scrollWidth
    }
  }, [nameInputValue, messengerInputValue, emailInputValue])

  useEffect(() => {
    if (phoneInputValue.length === 0) {
      setPhoneValue('')
    }

    if (focusedInput === 'phone' && phoneInputValue === '+7') {
      setPhoneValue('+7')
    }

    if ((focusedInput !== 'phone' || focusedInput === 'phone') && phoneInputValue !== '+7') {
      setPhoneValue(phoneInputValue)
    }

    
    if (!props.isModalVisible) {
      setPhoneValue('')
    }
  }, [focusedInput, phoneInputValue, props.isModalVisible])

  const sendForm = () => {
    try {
      createFeedBack({
        id: 0,
        name: nameInputValue,
        phone: phoneInputValue,
        messenger: messengerInputValue,
        email: emailInputValue,
        comment: commentTextArea
      })

      if (!isFeedbackSent) {
        setIsFeedbackSent(true)
        hideKeyboard()
      }
    } catch (error) {
      console.log('Error during form sending:', error)
    }
  }

  return (
    <AnimatePresence>
      {isFeedBackModalOpened && <motion.div
        onClick={props.onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.wrapper}
      >
        <div className={styles.overlay} />
        {!isPolicyVisible && !isFeedbackSent && <motion.form
          className={styles.main__wrapper}
          initial={{ top: '50%', left: '50%', translateX: '-50%', translateY: '-50%', opacity: 0 }}
          animate={{ 
            top: isKeyboardVisible ? 280 : '50%',
            opacity: 1
          }}
          exit={{ opacity: 0 }}
        >
          <div className={styles.title__wrapper}>
            <div className={styles.title__block}>
              <h3 className={styles.title}>{props.infoModalElements.title}</h3>
              <img alt="" className={styles.icon} src="/icons/cross_black.svg" onClick={props.closeModal} />
            </div>
            <p className={styles.description}>{props.infoModalElements.description}</p>
          </div>

          <div className={styles.inputs__block}>
            {props.infoModalElements.name && 
              <input
                ref={nameInputRef}
                className={styles.input}
                type="text"
                placeholder={focusedInput === 'name' ? '' : props.infoModalElements.name}
                onFocus={() => {
                  setIsKeyboardVisible()
                  setFocusedinput('name')
                }}
                readOnly
                value={nameInputValue ? nameInputValue : ''}
              />
            }

            {props.infoModalElements.phone &&
              <input
                className={styles.input}
                type="text"
                placeholder={focusedInput === 'phone' ? '' : props.infoModalElements.phone}
                onFocus={() => {
                  setIsKeyboardVisible()
                  setFocusedinput('phone')
                }}
                readOnly
                value={phoneValue}
              />
            }

            {props.infoModalElements.messenger &&
              <input
                ref={messengerInputRef}
                className={styles.input}
                type="text"
                placeholder={focusedInput === 'messenger' ? '' : props.infoModalElements.messenger}
                onFocus={() => {
                  setIsKeyboardVisible()
                  setFocusedinput('messenger')
                }}
                readOnly
                value={messengerInputValue ? messengerInputValue : ''}
              />
            }

            {props.infoModalElements.email &&
              <input
                ref={emailInputRef}
                className={styles.input}
                type="text"
                placeholder={focusedInput === 'email' ? '' : props.infoModalElements.email}
                onFocus={() => {
                  setIsKeyboardVisible()
                  setFocusedinput('email')
                }}
                readOnly
                value={emailInputValue ? emailInputValue : ''}
              />
            }
          </div>

          {props.infoModalElements.comment &&
            <textarea
              name=""
              id=""
              className={styles.text__area}
              placeholder={focusedInput === 'comment' ? '' : props.infoModalElements.comment}
              onFocus={() => {
                setIsKeyboardVisible()
                setFocusedinput('comment')
              }}
              readOnly
              value={commentTextArea ? commentTextArea : ''}
              />
          }
          
          <div className={styles.submit__block}>
            <button
              className={styles.submit__btn}
              type='button'
              disabled={isSubmitButtonDisabled}
              onClick={sendForm}
            >
              {props.infoModalElements.send_button_text}
            </button>
            <p className={styles.disclaimer}>
              Нажимая «Отправить», вы соглашаетесь<br/>
              <span className={styles.link} onClick={() => {
                  hideKeyboard()
                  setIsPolicyVisible(true)
                }}
              >
                с условиями политики о персональных данных
              </span>
            </p>
          </div>
        </motion.form>}

        {isFeedbackSent && <motion.div
          className={styles.final__modal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <img alt="" className={styles.final__img} src={`${process.env.REACT_APP_DIRECTUS_URL}/assets/${props.infoModalElements.final_image}`}/>
          <h2 className={styles.final__title}>{props.infoModalElements.final_title}</h2>
          <p className={styles.final__description}>{props.infoModalElements.final_description}</p>
          <img alt="" className={styles.final_close__icon} src="/icons/cross_black.svg" onClick={props.closeModal} />
        </motion.div>}

        {isPolicyVisible && props.infoModalElements.policy && <motion.div
          className={styles.policy}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={props.onClick}
        >
          <div className={styles.policy__wrapper}>
            <div className={styles.policy__title__block}>
              <h1 className={styles.policy__title}>{props.infoModalElements.policy_title}</h1>
              <img alt="" className={styles.policy__close__icon} src="/icons/cross_black.svg" onClick={() => setIsPolicyVisible(false)} />
            </div>
            <HTMLBlock className={styles.policy__content} rawHtml={props.infoModalElements.policy} />
          </div>
        </motion.div>}
        <TouchKeyboard isContactInfo />
      </motion.div>}
    </AnimatePresence>
    
  )
}