import client from '../../api/sdk';
import { Feedback } from '../../types/directus';
import { readItems, createItem, readSingleton, withToken } from '@directus/sdk';

const directusToken = process.env.DIRECTUS_STATIC_TOKEN || '';

export const getHomeScreen = async () => {
  return await client.request(
    readSingleton('home_screen', {
      fields: [
        '*'
      ]
    }),
  );
};

export const getPersonalInfoModal = async () => {
  return await client.request(
    readSingleton('personal_info_modal', {
      fields: [
        '*'
      ]
    }),
  );
};

export const getChabadHouses = async () => {
  return await client.request(
    readItems('chabad_houses_list', {
      fields: [
        '*',
        { contacts: ['*'] },
        { gallery: ['directus_files_id'] }
      ]
    }),
  );
};

export const getChabadHousesClusters = async () => {
  return await client.request(
    readItems('chabad_houses_clusers_list', {
      fields: [
        '*',
        { included_chabad_houses: ['*'] }
      ]
    }),
  );
};

export const getMapPageInfo = async () => {
  return await client.request(
    readSingleton('map_page', {
      fields: [
        '*'
      ]
    }),
  );
};

export const createFeedBack = async (data: Feedback): Promise<Feedback> => {
  return await client.request(withToken(directusToken, createItem('feedback', data)));
};