import { create } from 'zustand'
import { HomeScreen, PersonalInfoModal } from './types/directus'
import { GeoObjectType } from './types/geo-object-type'
import { ChabadHousesList } from './types/directus'

type CurrentpageType = 'home' | 'map' | 'editor' | 'docs' | 'no-internet'

type GalleryModalType = {
  isOpened: boolean
  id: number | null
}

type MapStoreType = {
  nearestChabadId: number | null
  setNearestChabadId: (data: number | null) => void
  isGeocoderError: boolean
  setGeocoderError: (data: boolean) => void
  redirectCountDown: number
  setRedirectCountDown: (data: number) => void
  overlayCountDown: {
    timeLeft: number,
    isOpened: boolean
  }
  screenSaverCountDown: {
    timeLeft: number,
    isOpened: boolean
  }
  setOverlayCountDown: (data: { timeLeft: number, isOpened: boolean }) => void
  setScreensaverCountDown: (data: { timeLeft: number, isOpened: boolean }) => void
  currentPage: CurrentpageType
  galleryModal: GalleryModalType
  geoObjectsList: GeoObjectType[]
  synagoguesList: ChabadHousesList[]
  selectedId: number | null,
  searchedCoodrinates: number[],
  setSelectedId: (newSelectedId: number) => void
  setSearchedCoordinates: (newSearchedCoodrinates: number[]) => void
  resetSearchedCoordinates: () => void
  resetSelectedId: () => void
  isKeyboardVisible: boolean
  setIsKeyboardVisible: () => void
  setIsKeyboardHidden: () => void
  adress: string
  setAdress: (newAdress: string) => void
  // deleteLastadressSymbol: () => void
  resetAdress: () => void
  setSynagoguesList: (data: ChabadHousesList[]) => void
  setGeoObjectsList: (data: any[]) => void
  resetGeoObjectsList: () => void
  setCurrentPage: (data: CurrentpageType) => void
  updateGalleryModal: (data: GalleryModalType) => void
  isFeedBackModalOpened: boolean,
  setIsFeedBackModalOpened: (data: boolean) => void
  homeInfo: HomeScreen,
  setHomeInfo: (data: HomeScreen) => void
  personalInfoModal: PersonalInfoModal
  setPersonalInfoModal: (data: PersonalInfoModal) => void
  focusedInput: string
  setFocusedInput: (data: string) => void
  nameInputValue: string,
  setNameInputValue: (data: string) => void
  phoneInputValue: string,
  setPhoneInputValue: (data: string) => void
  messengerInputValue: string
  setMessengerInputValue: (data: string) => void
  emailInputValue: string
  setEmailInputValue: (data: string) => void
  commentTextArea: string,
  setCommentTextArea: (data: string) => void
  isFeedbackSent: boolean
  setIsFeedbackSent: (data: boolean) => void
  clearAllInputsValues: () => void
  isPolicyVisible: boolean
  setIsPolicyVisible: (data: boolean) => void
  // updateAdress: (newAdress: string) => void,
  // isOverlayVisible: boolean
  // updateOverlay: (data: boolean) => void
}

export const useMapStore = create<MapStoreType>((set) => ({
  nearestChabadId: null,
  isGeocoderError: false,
  redirectCountDown: 90,
  overlayCountDown: {
    timeLeft: 60,
    isOpened: true
  },
  screenSaverCountDown: {
    timeLeft: 300,
    isOpened: false
  },
  currentPage: 'home',
  galleryModal: {
    isOpened: false,
    id: null
  },
  geoObjectsList: [],
  synagoguesList: [],
  selectedId: null,
  searchedCoodrinates: [],
  isKeyboardVisible: false,
  adress: '',
  setAdress: (newAdress: string) => set(() => ({ adress: newAdress })),
  // deleteLastadressSymbol: () => set((state) => ({ adress: state.adress!.slice(0, -1) })),
  // updateAdress: (newAdress: string) => set((state) => ({ adress: [...state.adress as any, newAdress] as any })),
  setIsKeyboardVisible: () => set(() => ({ isKeyboardVisible: true })),
  setIsKeyboardHidden: () => set(() => ({ isKeyboardVisible: false })),
  setSelectedId: (newSelectedId: number) => set(() => ({ selectedId: newSelectedId })),
  setSearchedCoordinates: (newSearchedCoodrinates: number[]) => set(() => ({ searchedCoodrinates: newSearchedCoodrinates })),
  resetSelectedId: () => set(() => ({ selectedId: null })),
  resetAdress: () => set(() => ({ adress: '' })),
  resetSearchedCoordinates: () => set(() => ({ searchedCoodrinates: [] })),
  setSynagoguesList: (data: ChabadHousesList[]) => set(() => ({ synagoguesList: data })),
  setGeoObjectsList: (data: GeoObjectType[]) => set(() => ({ geoObjectsList: data })),
  resetGeoObjectsList: () => set(() => ({ geoObjectsList: [] })),
  setCurrentPage: (data: CurrentpageType) => set(() => ({ currentPage: data })),
  updateGalleryModal: (data: GalleryModalType) => set(() => ({ galleryModal: data })),
  setRedirectCountDown: (data: number) => set(() => ({ redirectCountDown: data })),
  setOverlayCountDown: (data: { timeLeft: number, isOpened: boolean } ) => set(() => ({ overlayCountDown: data })),
  setScreensaverCountDown: (data: { timeLeft: number, isOpened: boolean } ) => set(() => ({ screenSaverCountDown: data })),
  setGeocoderError: (data: boolean ) => set(() => ({ isGeocoderError: data })),
  setNearestChabadId: (data: number | null ) => set(() => ({ nearestChabadId: data })),
  personalInfoModal: {
    id: 0,
    comment: null,
    description: null,
    email: null,
    messenger: null,
    name: null,
    phone: null,
    title: null,
  },
  setPersonalInfoModal: (data:PersonalInfoModal ) => set(() => ({ personalInfoModal: data })),
  focusedInput: '',
  setFocusedInput: (data:string ) => set(() => ({ focusedInput: data })),
  nameInputValue: '',
  setNameInputValue: ( data: string ) => set(() => ({ nameInputValue: data })),
  phoneInputValue: '+7',
  setPhoneInputValue: ( data: string ) => set(() => ({ phoneInputValue: data })),
  messengerInputValue: '',
  setMessengerInputValue: ( data: string ) => set(() => ({ messengerInputValue: data })),
  emailInputValue: '',
  setEmailInputValue: ( data: string ) => set(() => ({ emailInputValue: data })),
  commentTextArea: '',
  setCommentTextArea: ( data: string ) => set(() => ({ commentTextArea: data })),
  homeInfo: {
    id: 0,
  },
  setHomeInfo: ( data: HomeScreen ) => set(() => ({ homeInfo: data })),
  isFeedBackModalOpened: false,
  setIsFeedBackModalOpened: ( data: boolean ) => set(() => ({ isFeedBackModalOpened: data })),
  isFeedbackSent: false,
  setIsFeedbackSent: ( data: boolean ) => set(() => ({ isFeedbackSent: data })),
  clearAllInputsValues: () => set(() => ({ nameInputValue: '', phoneInputValue: '+7', messengerInputValue: '', emailInputValue: '', commentTextArea: '' }) ),
  isPolicyVisible: false,
  setIsPolicyVisible: ( data: boolean ) => set(() => ({ isPolicyVisible: data })),
}))
