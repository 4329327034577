import { useEffect } from "react"
import { useMapStore } from "../../../store"

export const OverlayChecker = () => {
  const overlayCountDown = useMapStore((state) => state.overlayCountDown)
  const setFocusedInput = useMapStore((state) => state.setFocusedInput)
  const setIsFeedbackSent = useMapStore((state) => state.setIsFeedbackSent)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const clearAllInputsValues = useMapStore((state) => state.clearAllInputsValues)
  const setIsFeedBackModalOpened = useMapStore((state) => state.setIsFeedBackModalOpened)
  const setIsPolicyVisible = useMapStore((state) => state.setIsPolicyVisible)
  const updateOverlayCountDown = useMapStore((state) => state.setOverlayCountDown)
  const updateScreensaverCountDown = useMapStore((state) => state.setScreensaverCountDown)

  useEffect(() => {
    if (!overlayCountDown.isOpened) {
      updateScreensaverCountDown({ isOpened: false, timeLeft: 300 })

      let timer

      timer = setInterval(() => {
        updateOverlayCountDown({ isOpened: overlayCountDown.isOpened, timeLeft: overlayCountDown.timeLeft - 1 })
      }, 1000)

      if (overlayCountDown.timeLeft === 0) {
        updateOverlayCountDown({ isOpened: true, timeLeft: 60 })
        setFocusedInput('')
        setIsPolicyVisible(false)
        setIsFeedBackModalOpened(false)
        setIsFeedbackSent(false)
        hideKeyboard()
        clearAllInputsValues()
      }

      return () => clearInterval(timer)
    }

    if (overlayCountDown.isOpened) {
      updateScreensaverCountDown({ isOpened: false, timeLeft: 300 })
    }

  }, [clearAllInputsValues, hideKeyboard, overlayCountDown.isOpened, overlayCountDown.timeLeft, setFocusedInput, setIsFeedBackModalOpened, setIsFeedbackSent, setIsPolicyVisible, updateOverlayCountDown, updateScreensaverCountDown])
  
  return (
    <div />
  )
}