import { useMapStore } from '../../../store'
import Keyboard from 'react-simple-keyboard'
import { formatAddress } from '../../../helpers/format-adress'
import { GeoObjectType } from '../../../types/geo-object-type'
import { AnimatePresence, motion } from 'framer-motion'
import styles from './TouchKeyboard.module.css'
import { useState } from 'react'

export const TouchKeyboard = (props: {
  backButtonText?: string | null | undefined
  isContactInfo?: boolean
}) => {
  const adress = useMapStore((state) => state.adress)
  const setAdress = useMapStore((state) => state.setAdress)
  const hideKeyboard = useMapStore((state) => state.setIsKeyboardHidden)
  const isKeyboardVisible = useMapStore((state) => state.isKeyboardVisible)
  const setGeoObjectsList = useMapStore((state) => state.setGeoObjectsList)
  const setGeocoderError = useMapStore((state) => state.setGeocoderError)
  const resetSelectedId = useMapStore((state) => state.resetSelectedId)
  const resetSearchedCoordinates = useMapStore((state) => state.resetSearchedCoordinates)
  const resetAdress = useMapStore((state) => state.resetAdress)
  const resetGeoObjectsList = useMapStore((state) => state.resetGeoObjectsList)
  const setSynagoguesList = useMapStore((state) => state.setSynagoguesList)
  const setCurrentPage = useMapStore((state) => state.setCurrentPage)
  const updateGalleryModal = useMapStore((state) => state.updateGalleryModal)
  const updateOverlay = useMapStore((state) => state.setOverlayCountDown)
  const setNearestChabadId = useMapStore((state) => state.setNearestChabadId)
  const setRedirectCountDown = useMapStore((state) => state.setRedirectCountDown)
  const focusedInput = useMapStore((state) => state.focusedInput)
  const setNameValue = useMapStore((state) => state.setNameInputValue)
  const nameInputValue = useMapStore((state) => state.nameInputValue)
  const phoneInputValue = useMapStore((state) => state.phoneInputValue)
  const setPhoneInputValue = useMapStore((state) => state.setPhoneInputValue)
  const messengerInputValue = useMapStore((state) => state.messengerInputValue)
  const setMessengerInputValue = useMapStore((state) => state.setMessengerInputValue)
  const emailInputValue = useMapStore((state) => state.emailInputValue)
  const setEmailInputValue = useMapStore((state) => state.setEmailInputValue)
  const commentTextArea = useMapStore((state) => state.commentTextArea)
  const setCommentTextArea = useMapStore((state) => state.setCommentTextArea)

  const handleBackButtonClick = () => {
    resetSelectedId()
    resetSearchedCoordinates()
    resetAdress()
    resetGeoObjectsList()
    updateGalleryModal({ isOpened: false, id: null })
    setSynagoguesList([])
    updateOverlay({ isOpened: false, timeLeft: 60 })
    setRedirectCountDown(60)
    setGeocoderError(false)
    setNearestChabadId(null)
    hideKeyboard()
    setCurrentPage('home')
  }

  const sendAdress = async () => {
    if (adress && adress.length > 0) {
      try {
        const request = await fetch(`https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.REACT_APP_API_KEY}&geocode=${adress}&lang=ru_RU&format=json`)        
        const response = await request.json()
        const listOfCities = response.response.GeoObjectCollection.featureMember
        const filteredListOfCities: GeoObjectType[] = []
        if (listOfCities.length > 0) {
          setGeocoderError(false)
          listOfCities.forEach((element: any) => {
            if (element && (element.GeoObject.description.includes('Московская область') || element.GeoObject.description.includes('Москва'))) {
              const formatedAdress = formatAddress(element.GeoObject.description.replace('Россия', ''), element.GeoObject.name)
              filteredListOfCities.push({
                adress: formatedAdress,
                coordinates: element.GeoObject.Point.pos.split(' ').map(Number)
              })
            }
          })
        }

        if (filteredListOfCities.length > 0) {
          setGeoObjectsList(filteredListOfCities)
        } else {
          setGeoObjectsList([{adress: null, coordinates: []}])
        }
      } catch (error) {
        setGeocoderError(true)
      }
    }
  }
  
  const buttonTheme = [
    {
      class: "keyboard-button",
      buttons: "1 2 3 4 5 6 7 8 9 0 й ц у к е н г ш щ з х ъ ф ы в а п р о л д ж э {shift} я ч с м и т ь б ю . , ?123 {bksp} {enter} "
    },
    {
      class: "custom-bksp",
      buttons: "{bksp}"
    },
    {
      class: "custom-shift",
      buttons: "{shift}"
    },
    {
      class: "custom-space",
      buttons: "{space}"
    },
    {
      class: "custom-enter",
      buttons: "{enter}"
    }
  ];

  const layouts = {
    english: {
      default: [
        "1 2 3 4 5 6 7 8 9 0",
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "Z X C V B N M {bksp}",
        ", . _ {space} — @ + {enter}"
      ]
    },
    russian: {
      default: [
        "1 2 3 4 5 6 7 8 9 0",
        "Й Ц У К Е Н Г Ш Щ З Х Ъ",
        "Ф Ы В А П Р О Л Д Ж Э",
        "Я Ч С М И Т Ь Б Ю {bksp}",
        ", . _ {space} — @ + {enter}"
      ]
    }
  };

  return <>
    {!props.isContactInfo && <motion.button
      className={styles.back__button_inactive}
      type='button'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      onClick={handleBackButtonClick}
    >
      <img
        className={styles.back__icon}
        src="/icons/arrow_light.svg"
        alt="Стрелка назад"
      />
      {props.backButtonText}
    </motion.button>}

    <AnimatePresence>
      {isKeyboardVisible && <motion.div
        className={props.isContactInfo ? styles.contact__info__wrapper :styles.wrapper}
        initial={{ opacity: 0, translateY: '517px' }}
        animate={{ opacity: 1, translateY: '0' }}
        transition={{ duration: .3 }}
        exit={{ opacity: 0, translateY: '517px' }}
      >
        {!props.isContactInfo && <button
          className={styles.back__button_active}
          type='button'
          onClick={handleBackButtonClick}
        >
          <img
            className={styles.back__icon}
            src="/icons/arrow_light.svg"
            alt="Стрелка назад"
          />
          {props.backButtonText}
        </button>}

        {!props.isContactInfo && <button
          className={styles.hide__btn}
          type='button'
          onClick={() => hideKeyboard()}
        >
          Скрыть клавиатуру
        </button>}

        <Keyboard
          // onChange={(e) => !adress ? setAdress(e) : setAdress(adress + e[e.length - 1])}
          // onChange={(e) => setAdress(e)}
          layout={(focusedInput === 'messenger' || focusedInput === 'email') ? layouts.english : layouts.russian}
          buttonTheme={buttonTheme}
          onKeyPress={(key) => {
            if (key === '{enter}') {
              if (focusedInput === 'adress') {
                sendAdress()
                hideKeyboard()
              }
            }

            if (key === '+') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '+')
              }
              
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '+')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '+')
              }
            }

            if (key === '—') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '—')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '—')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '—')
              }
            }

            if (key === '_') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '_')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '_')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '_')
              }
            }

            if (key === '@') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '@')
              }
              
              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '@')
              }
              
              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '@')
              }
            }

            if (key === '1') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '1')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '1')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '1')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '1')
              }

              if (focusedInput === 'adress') {
                setAdress(adress + '1')
              }
            }

            if (key === '2') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '2')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '2')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '2')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '2')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '2')
              }
            }

            if (key === '3') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '3')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '3')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '3')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '3')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '3')
              }
            }

            if (key === '4') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '4')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '4')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '4')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '4')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '4')
              }
            }

            if (key === '5') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '5')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '5')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '5')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '5')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '5')
              }
            }

            if (key === '6') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '6')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '6')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '6')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '6')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '6')
              }
            }

            if (key === '7') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '7')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '7')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '7')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '7')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '7')
              }
            }

            if (key === '8') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '8')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '8')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '8')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '8')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '8')
              }
            }

            if (key === '9') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '9')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '9')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '9')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '9')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '9')
              }
            }

            if (key === '0') {
              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue + '0')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '0')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '0')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '0')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + '0')
              }
            }

            if (key === 'Й') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'й')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'й')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'й')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'й')
              }
            }
            
            if (key === 'Ц') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ц')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ц')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ц')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ц')
              }
            }

            if (key === 'У') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'у')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'у')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'у')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'у')
              }
            }

            if (key === 'К') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'к')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'к')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'к')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'к')
              }
            }

            if (key === 'Е') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'е')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'е')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'е')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'е')
              }
            }


            if (key === 'Н') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'н')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'н')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'н')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'н')
              }
            }

            if (key === 'Г') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'г')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'г')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'г')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'г')
              }
            }
            
            if (key === 'Ш') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ш')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ш')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ш')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ш')
              }
            }
            
            if (key === 'Щ') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'щ')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'щ')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'щ')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'щ')
              }
            }

            if (key === 'З') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'з')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'з')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'з')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'э')
              }
            }
                      
            if (key === 'Х') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'х')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'х')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'х')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'х')
              }
            }

            if (key === 'Ъ') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ъ')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ъ')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ъ')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ъ')
              }
            }

            if (key === 'Ф') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ф')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ф')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ф')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ф')
              }
            }

            if (key === 'Ы') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ы')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ы')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ы')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ы')
              }
            }

            if (key === 'В') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'в')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'в')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'в')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'в')
              }
            }

            if (key === 'А') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'а')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'а')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'а')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'а')
              }
            }

            if (key === 'П') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'п')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'п')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'п')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'п')
              }
            }

            if (key === 'Р') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'р')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'р')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'р')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'р')
              }
            }

            if (key === 'О') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'о')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'о')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'о')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'о')
              }
            }

            if (key === 'Л') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'л')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'л')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'л')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'л')
              }
            }

            if (key === 'Д') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'д')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'д')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'д')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'д')
              }
            }

            if (key === 'Ж') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ж')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ж')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ж')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ж')
              }
            }

            if (key === 'Э') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'э')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'э')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'э')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'э')
              }
            }

            if (key === 'Я') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'я')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'я')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'я')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'я')
              }
            }

            if (key === 'Ч') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ч')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ч')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ч')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ч')
              }
            }

            if (key === 'С') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'с')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'с')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'с')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'с')
              }
            }

            if (key === 'М') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'м')
              }
              
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'м')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'м')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'м')
              }
            }

            if (key === 'И') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'и')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'и')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'и')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'и')
              }
            }

            if (key === 'Т') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'т')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'т')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'т')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'т')
              }
            }

            if (key === 'Ь') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ь')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ь')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ь')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ь')
              }
            }

            if (key === 'Б') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'б')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'б')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'б')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'б')
              }
            }

            if (key === 'Ю') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + 'ю')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'ю')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + 'ю')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + 'ю')
              }
            }
            
            if (key === 'Q') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'q')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'q')
              }
            }

            if (key === 'W') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'w')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'w')
              }
            }

            if (key === 'E') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'e')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'e')
              }
            }

            if (key === 'R') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'r')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'r')
              }
            }

            if (key === 'T') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 't')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 't')
              }
            }

            if (key === 'Y') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'y')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'y')
              }
            }

            if (key === 'U') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'u')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'u')
              }
            }

            if (key === 'I') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'i')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'i')
              }
            }

            if (key === 'O') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'o')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'o')
              }
            }

            if (key === 'P') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'p')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'p')
              }
            }

            if (key === 'A') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'a')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'a')
              }
            }

            if (key === 'S') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 's')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 's')
              }
            }

            if (key === 'D') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'd')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'd')
              }
            }

            if (key === 'F') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'f')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'f')
              }
            }

            if (key === 'G') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'g')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'g')
              }
            }

            if (key === 'H') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'h')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'h')
              }
            }

            if (key === 'J') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'j')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'j')
              }
            }

            if (key === 'K') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'k')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'k')
              }
            }

            if (key === 'L') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'l')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'l')
              }
            }

            if (key === 'Z') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'z')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'z')
              }
            }

            if (key === 'X') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'x')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'x')
              }
            }

            if (key === 'C') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'c')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'c')
              }
            }

            if (key === 'V') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'v')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'v')
              }
            }

            if (key === 'B') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'b')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'b')
              }
            }

            if (key === 'N') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'n')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'n')
              }
            }

            if (key === 'M') {
              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + 'm')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + 'm')
              }
            }

            if (key === ',') {
              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + ',')
              }

              if (focusedInput === 'adress') {
                setAdress(adress + ',')
              }
            }

            if (key === '.') {
              if (focusedInput === 'adress') {
                setAdress(adress + '.')
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue + '.')
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue + '.')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + '.')
              }
            }

            if (key === '{space}') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue + ' ')
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea + ' ')
              }
              
              if (focusedInput === 'adress') {
                setAdress(adress + ' ')
              }
            }

            if (key === '{bksp}') {
              if (focusedInput === 'name') {
                setNameValue(nameInputValue.replace(/.$/, ""))
              }

              if (focusedInput === 'phone') {
                setPhoneInputValue(phoneInputValue.replace(/.$/, ""))
              }

              if (focusedInput === 'messenger') {
                setMessengerInputValue(messengerInputValue.replace(/.$/, ""))
              }

              if (focusedInput === 'email') {
                setEmailInputValue(emailInputValue.replace(/.$/, ""))
              }

              if (focusedInput === 'comment') {
                setCommentTextArea(commentTextArea.replace(/.$/, ""))
              }

              if (focusedInput === 'adress') {
                setAdress(adress.replace(/.$/, ""))
              }
            }
          }}
        />
      </motion.div>}
    </AnimatePresence>
    

    {/* {!isKeyboardVisible && <motion.div
      className={props.isContactInfo ? styles.contact__info__wrapper :styles.wrapper}
      initial={{ opacity: 1, translateY: '0' }}
      animate={{ opacity: 0, translateY: '517px' }}
      transition={{ duration: .3 }}
    >
      <Keyboard
        onChange={(e) => setAdress(e)}
        layout={
          {
            default: [
              "1 2 3 4 5 6 7 8 9 0",
              "й ц у к е н г ш щ з х ъ",
              "ф ы в а п р о л д ж э",
              "{shift} я ч с м и т ь б ю , . {bksp}",
              "{space}"
            ],
          }
        }
        buttonTheme={buttonTheme}
        onKeyPress={(key) => {
          if (key === '{enter}' && !props.isContactInfo) {
            sendAdress()
            hideKeyboard()
          }
        }}
      />
    </motion.div>} */}
  </>
}